import { quest } from "@fscrypto/domain";
import { ecoToDisplayName } from "@fscrypto/domain/quest";
import { Avatar, cn } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import { $path } from "remix-routes";
import config from "~/utils/config";
import { linkButtonVariants } from "./style-variants";

export function EcosystemLinkButton({ ecosystem, selected }: { ecosystem: quest.QuestEcosystem; selected?: boolean }) {
  return (
    <Link
      key={ecosystem}
      to={$path("/earn/:ecosystem", { ecosystem })}
      className={cn(
        "bg-opacity-55 flex w-44 items-center justify-center gap-2 rounded-xl border px-6 py-2",
        linkButtonVariants({ variant: selected ? ecosystem : "none" }),
        clsx({ "drop-shadow-lg": selected }),
      )}
    >
      <Avatar
        src={config.CLOUDINARY_PROJECT_ICON_PATH + `${ecosystem}.svg`}
        size="sm"
        className={cn(linkButtonVariants({ variant: selected ? ecosystem : "none" }))}
      />{" "}
      <p>{ecoToDisplayName[ecosystem]}</p>
    </Link>
  );
}
