import { ecoToDisplayName, questEcosystemsVisible } from "@fscrypto/domain/quest";
import { Avatar, Dropdown } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { Check, ChevronDownIcon } from "lucide-react";
import { $path } from "remix-routes";
import config from "~/utils/config";

export function EcosystemDropdown({ ecosystem }: { ecosystem?: string }) {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="w-full">
        <div className="dark:bg-gray-90 dark:text-gray-40 relative w-full cursor-default rounded-md border bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm sm:leading-6">
          {ecosystem ? (
            <>
              <div className="flex flex-wrap items-center gap-2">
                <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${ecosystem}.svg`} size="sm" />
                <p>{ecoToDisplayName[ecosystem]}</p>
              </div>
            </>
          ) : (
            <p>Select chain</p>
          )}

          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="text-muted-foreground size-5" aria-hidden="true" />
          </span>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content align="start" className="z-50 w-full min-w-[300px]">
        {questEcosystemsVisible.map((q) => {
          const isSelected = q === ecosystem;
          return (
            <Dropdown.Item asChild key={q}>
              <Link to={$path("/earn/:ecosystem", { ecosystem: q })}>
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-wrap items-center gap-2">
                    <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${q}.svg`} size="sm" />
                    <p>{ecoToDisplayName[q]}</p>
                  </div>
                  {isSelected && <Check className="text-blue-40 size-4" />}
                </div>
              </Link>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Content>
    </Dropdown.Root>
  );
}
